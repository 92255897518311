p {
  font-size: 18px;
}

header#blocket-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  box-sizing: border-box;
  margin-bottom: 30px;
  width: 100%;
  z-index: 1000;
  height: 115px;
  /* overflow: hidden; */
  background-color: #fff;
  -webkit-box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.1);
  background: linear-gradient(to bottom, #140955, #08042b);
  padding: 0 20px;
}

@media (max-width: 350px) {
  header#blocket-header {
    margin-bottom: 15px;
  }
}

.logo-container {
  /* width: 300px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.logo-container p {
  margin-top: 14px;
  text-align: center;
  color: white;
}

.badge-container {
  display: none;
}

@media(min-width: 619px) {
  header#blocket-header {
    padding: 0 30px;
  }

  .logo-container {
    width: auto;
  }

  .badge-container {
    display: block;
  }
}

@media (min-width: 1000px) {
  header#blocket-header {
    height: 85px;
    padding: 0 50px;
  }
  .logo-container {
    flex-direction: row;
  }
  .logo-container p {
    border-left: 1px solid white;
    margin: 11px 0 11px 30px;
    padding: 11px 0 11px 29px;
  }
}

.plus-collab {
  display: block;
  height: 13px;
  margin: 0 14px;
}

.hero-logo-container {
  width: 50%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: flex-end;
  -o-object-fit: contain;
     object-fit: contain;
  -webkit-box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.1);
}

#hero-logo {
  width: 88px;
  height: 29px;
}

#partner-logo {
  height: 21px;
}
#partner-logo-full {
  height: 29px;
}

img.store-badge {
  height: 38px;
}

.container {
  display: block;
  width: 100%;
  overflow: hidden;
}

.container-inner {
  width: 100%;
  background-color: #fff;
}

.inner {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.small-inner {
  width: 70%;
  margin-left: auto;
  margin-right: 25%;
}

.container,
.container-inner,
.inner,
input {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

input {
  -webkit-appearance: none;
  border-radius:0;
}

::placeholder {
  opacity: 0.49;
  font-size: 18px;
  color: #000;
}

/* p#page-heading {
  font-size: 20px;
  text-align: center;
  padding: 0 3% 36px;
} */

.form-heading,
.sub-heading {
  color: #000;
}

.drop-down-tip {
  height: 8px;
  position: absolute;
  top: -8px;
}
.drop-down-tip.left {
  left: 36px;
}
.drop-down-tip.right {
  right: 32px;
  right: calc(5% + 8px);
}

#title-and-images-edit-form {
  position: relative;
  display: none;
  padding: 20px 0 30px 0;
  border-top: 1px solid #f4f3f3;
  background: #f9f9f9;
}
#title-and-images-edit-form.permanent {
  padding-top: 0;
  display: block;
  border: none;
  background: none;
}
#title-and-images-edit-form.permanent .drop-down-tip {
  display: none;
}

#ad-title-heading {
  margin-left: 5%;
  font-family: 'Lato', sans-serif;
}

.ad-title-container, .campaign-code-container {
  position: relative;
}

#campaign-code {
  font-size: 17px;
}

#submit-campaign-code {
  position: absolute;
  top: 9px;
  right: 9px;
  height: 30px;
  padding: 0 17px;
  border: none;
  border-radius: 4px;
  background-color: #2a91f5;
  color: #fff;
  font-size: 14px;
  font-weight: 800;
  outline: none;
  cursor: pointer;
  transition: width .2s;
}
#submit-campaign-code.confirmed {
  background-color: transparent;
  color: #08D88C;
  font-size: 20px;
  cursor: default;
}
#submit-campaign-code .checkmark {
  display: none;
  height: 15px;
}
#submit-campaign-code.confirmed span.submit {
  display: none;
}
#submit-campaign-code.confirmed .checkmark {
  display: block;
}
#campaign-code:not([touched]) + #submit-campaign-code {
  background-color: #e5e5e5;
}
#submit-campaign-code:not(.confirmed):hover {
  opacity: 0.8;
}

@media (min-width: 375px) {
  #submit-campaign-code {
    height: 38px;
  }
}

/* .ad-title-container .clear-input {
  right: 40px;
} */

#ad-title {
  padding-right: 48px;
}
/* #ad-title[value]:not(:focus) {
  border-color: transparent !important;
  border-left: none;
  padding-left: 0;
}
#ad-title:focus + #edit-title {
  pointer-events: auto;
} */
/* #edit-title span.done {
  display: none;
} */
/* #ad-title:focus + #edit-title span.done {
  display: block;
} */
/* #ad-title:focus + #edit-title span.edit {
  display: none;
} */

#edit-title {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding-left: 2%;
  padding-right: 2%;
  margin-right: 8%;
  height: 53px;
  -webkit-appearance: none;
  background: none;
  border: none;
  outline: none;
  color: #42a5f5;
  pointer-events: none;
  cursor: default;
}
/* #ad-title[value] + #edit-title {
  display: block;
} */
#edit-title {
  display: none;
}

/* ad container */
#ad-preview {
  display: -webkit-box;
  display: -ms-flexbox;
  padding: 0 30px;
  display: flex;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.15); */
}
#ad-preview.hidden {
  display: none;
}

#edit-title-and-images-button {
  display: none;
  margin-left: 30px;
  padding: 15px 0;
  -webkit-appearance: none;
  background: none;
  border: none;
  font-size: 16px;
  color: #42a5f5;
  outline: none;
}
#edit-title-and-images-button.visible {
  display: block;
}

@media (max-width: 350px) {
  #ad-preview {
    padding-right: 15px;
    padding-left: 15px;
  }
  .drop-down-tip.left {
    left: 20px;
  }
  #edit-title-and-images-button {
    margin-left: 15px;
  }
}

#ad-preview-title {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow:ellipsis;
     text-overflow:ellipsis
}

.ad-feat-row {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-bottom: 6px;
}

.ad-checkmark {
  height: 16px;
  margin-right: 8px;
}

#ad-preview-img {
  position: relative;
  min-width: 100px;
  width: 100px;
  min-height: 100px;
  height: 100px;
  border-radius: 10px;
  background-color: silver;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#ad-preview-img-num {
  font-size: 12px;
  padding: 2px;
  position: absolute;
  bottom: 3px;
  border-radius: 50%;
  background: white;
  border: none;
  width: 16px;
  height: 16px;
  right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#ad-preview-img-num.hidden {
  display: none;
}

.ad-preview-info {
  overflow: hidden;
  padding-left: 5%;
}

#ad-desc {
  font-size: 16px;
}

.ad-feat-row {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-bottom: 6px;
}

.ad-checkmark {
  height: 16px;
  margin-right: 8px;
}

#images-title {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.images-wrapper {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

#images-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}

.image-input {
  display: none;
}

#image-template {
  display: none;
}

.ad-img-container {
  -webkit-flex-basis: calc(100% / 4);
  flex-basis: calc(100% / 4);
  width: 25%;
  float: left;
  padding: 5px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

.ad-img-aspect {
  width: 100%;
  height: 0;
  position: relative;
  padding-top: 100%;
}

.ad-img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  border-radius: 10px;
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.ad-img-overlay {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  font-size: 30px;
  font-weight: 900;
  color: #fff;
  visibility: hidden;
}
.ad-img-overlay.visible { visibility: visible; }
.ad-img-overlay.hidden { visibility: hidden; }

.ad-img-spinner {
  display: none;
}
.ad-img-spinner.visible {
  display: block;
}
.ad-img-spinner.visible + .ad-img-plus {
  display: none;
}

.ad-img-clear {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 2;
  visibility: hidden;
}
.has-image + .ad-img-container .ad-img-clear {
  visibility: visible;
}

.sub-heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
}

.sub-sub-heading {
  font-size: 18px;
  font-weight: bold;
  margin-top: 24px;
}

.section-label {
  font-size: 22px;
  font-weight: 700;
  color: #000;
  text-align: center;

  padding: 12px 0;
  width: 100%;
  background-color: #f2f2f4;
}

.cost-container {
  display: flex;
  flex-direction: row;
  height: 55px;
  margin: 30px auto 20px;
  -o-object-fit: contain;
     object-fit: contain;
  background-color: #fff;
  border-radius: 8px;
  -webkit-box-shadow: 0 -3px 27px 0 rgba(0, 0, 0, 0.07);
          box-shadow: 0 -3px 27px 0 rgba(0, 0, 0, 0.07);
}

@media(min-width: 375px) {
  .cost-container {
    height: 65px;
  }
}

.price-sug {
  flex: 1;
  justify-content: flex-start;
  padding-left: 15px;
  font-size: 20px;
  line-height: 1.8;
  color: #272727;
  height: 100%;
  border-radius: 4px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  white-space: nowrap;
  overflow: hidden;
}

.price-sug-value {
  height: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #f1f1f1;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.tool-tip {
  display: none;
  position: absolute;
  bottom: 49px;
  left: -216px;
  max-width: 260px;
  width: 90vw;
  padding: 18px 22px;
  /* background-image: url('/img/tooltip-bg.svg'); */
  opacity: 0;
  background-color: #FFF;
  border-radius: 8px;
  -webkit-box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.15);
  -webkit-animation: fadeIn 0.2s ease-in;
  -webkit-animation-fill-mode: forwards;
}

.show {
  display: block;
}

.show-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.hide {
  display: none;
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes fadeIn {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

.tool-tip p {
  font-size: 16px;
  line-height: 1.38;
}

/* right: 39px; */
.arrow-down {
  display: none;
  position: absolute;
  top: -28px;
  right: -5%;
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top: 16px solid #FFF;
  -webkit-animation: fadeIn 0.2s ease-in;
  -webkit-animation-fill-mode: forwards;
}

@media(min-width: 375px) {
  .arrow-down {
    /* right: 63px; */
  }
}

@media(min-width: 425px) {
  .arrow-down {
    /* right: 86px; */
  }
}

@media(min-width: 500px) {
  .arrow-down {
    /* right: 117px; */
  }
}

.insurance-promo {
  margin-bottom: 28px;
  /* margin-top: 0; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.insurance-promo img {
  height: 50px;
  margin-right: 16px;
}

.insurance-promo p {
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0;
  color: #000;
}

.sort-up-icon-with-border {
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 17px solid #f4f3f3;
  position: absolute;
  bottom: -9px;
}

.sort-up-icon-with-border::before {
  width: 0;
  height: 0;
  content: "";
  bottom: -18px;
  left: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #f9f9f9;
  position: absolute;
  z-index: 1;
}

input[name='adPrice'] {
  /* width: 310px;
    height: 36px;
    border-radius: 32.5px;
    box-shadow: 0 -3px 27px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #f1f1f1; */
  height: 70%;
  font-weight: bold;
  color: #272727;
  border: none;
  outline: none;
  padding: 0px;
}

.currency {
  font-size: 18px;
  font-weight: bold;
  color: #272727;
  margin-right: 0;
  margin: 0 6px 0 4px;
  padding: 0;
  height: 100%;
  border-radius: 4px;
  border-bottom-right-radius: 60px;
  border-top-right-radius: 60px;
}

.sticky-top {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 495px;
  -webkit-animation: smoothScroll 1s forwards;
          animation: smoothScroll 1s forwards;
}

/* required to fix issue in samsung mobile with mozilla */
#ad-price {
  font-size: 18px;
  width: 38px;
  height: 100%;
  padding: 0;
  text-align: right;
  -moz-appearance: textfield;
}
#ad-price::-webkit-inner-spin-button,
#ad-price::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#ad-price-with-credits {
  padding: 12px 46px;
  background: #fdf1ab;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #272727;
}

@-webkit-keyframes smoothScroll {
	0% {
		-webkit-transform: translateY(-40px);
		        transform: translateY(-40px);
	}
	100% {
		-webkit-transform: translateY(0px);
		        transform: translateY(0px);
	}
}

@keyframes smoothScroll {
	0% {
		-webkit-transform: translateY(-40px);
		        transform: translateY(-40px);
	}
	100% {
		-webkit-transform: translateY(0px);
		        transform: translateY(0px);
	}
}

.rek-price {
  width: 100%;
  height: 62px;
  z-index: 100;
  -webkit-box-shadow: 0 13px 24px 0 rgba(0, 0, 0, 0.06);
          box-shadow: 0 13px 24px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px rgba(151, 151, 151, 0.07);
  background-color: #FFF;
  left: auto;
  right: auto;
  -webkit-transition: all 0.5s ease;
  -o-transition: position 10s;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.sticky-top + #cost-inner {
  padding-top: 92px;
}

.rek-price p {
  font-size: 20px;
  line-height: 1.8;
  color: #272727;
}

#cost-inner {
  padding-top: 30px;
}

#cost-inner .sub-heading {
  margin-bottom: 7px;
}

@media (min-width: 375px) {
  .rek-price p {
    font-size: 22px;
  }
}

@media (min-width: 500px) {
  .rek-price p {
    font-size: 22px;
  }

  .section-label {
    font-size: 22px;
  }
}

/* delivery address info */
.section-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  margin-top: 30px;
}

.section-header img {
  margin-right: 16px;
  margin-bottom: 12px;
}

.extra-info--title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;


}
.extra-info {
  padding: 16px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;

}

.time-slot-day {
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.10);
}

.time-slot-day#today {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.10);
}

.time-slot-day#tomorrow {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid rgba(121, 9, 9, 0.1);
}

.time-slot-day.selected-day {
  text-align: center;
  font-weight: bold;
  color: #FFF;
  background-color: #2a91f5;
  cursor: pointer;
  border: 1px solid #2a91f5;
}

/* pick-up container */
.pick-up {
  margin-top: 48px;
  margin-bottom: 22px;
}

/* common */
.link {
  color: #2a91f5;
  cursor: pointer;
}

input {
  width: 100%;
  height: 48px;
  padding: 0 8px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #eceef2;
}

.address-container {
  position: relative;
}

#to-address, #from-address {
  padding-right: 38px;
}

.clear-input {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 1px;
  right: 0;
  padding: 13px;
  cursor: pointer;
}

.clear-input:hover {
  opacity: .8;
}

input:not(:valid) ~ .clear-input {
	display: none;
}

@media (min-width: 375px) {
  input {
    height: 55px;
    border-radius: 4px;
  }

  #to-address, #from-address {
    padding-right: 48px;
  }

  .clear-input {
    top: 5px;
    right: 3px;
  }
}

.section-separator {
  width: 90%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 36px auto;
}

.input-separator {
  width: 100%;
  height: 1px;
  background-color: #ebebeb;
  /* width: 100%;
  border-bottom: 1px solid #ebebeb;
  margin: 16px auto; */
}

.sub-heading {
  font-weight: bold;
}

#time-slot-anchor.sub-heading {
  margin-bottom: 8px;
}

@media (min-width: 500px) {
  .container {
    margin: auto;
    max-width: 495px;
    width: 495px;
    margin-bottom: 50px;
    border-radius: 19px;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.08);
  }

  .container-inner {
    width: 100%;
    padding-top: 30px;
  }

  .hero-logo-container {
    width: 168px;
  }

  #hero-logo {
    width: 110px;
    height: 35px;
  }

  #partner-logo {
    height: 27px;
  }
  #partner-logo-full {
    height: 35px;
  }

  .plus-collab {
    display: block;
  }

  p.heading {
    width: 418px;
    padding: 54px 0;
  }
}

.cta {
  display: block;
  height: 48px;
  margin: 16px auto 30px;
  border-radius: 30px;
  border: none;
  background-color: #2a91f5;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;

  font-size: 20px;
  font-weight: 800;
  text-align: center;
  color: #ffffff;
}

.cta:hover {
  opacity: 0.8;
}

@media (min-width: 375px) {
  .cta {
    height: 60px;
    border-radius: 30px;

    font-size: 22px;
    font-weight: 900;
  }
}

.insurance {
  margin-top: 0;
}

@media (min-width: 500px) {
  .insurance {
    margin-top: 40px;
  }
}

/* time slots */
.slots-header {
  background-color: #FFF;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.slots-header p {
  width: 50%;
  text-align: center;
  padding: 12px 0;
}

#slot-container-fixed,
#slot-container-today,
#slot-container-tomorrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

#slot-container-fixed {
  padding-bottom: 20px;
}

#slot-container-fixed p::first-letter {
  text-transform: capitalize;
}

input[type='checkbox' i] {
  -webkit-appearance: none;
}

.time-slot { display: none; }
.time-slot + div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 900;
  text-align: center;
  border: none;
  border-radius: 4px;
  background-color: #e5e5e5;
  cursor: pointer;
}

.time-slot:checked + div {
  background-color: #2a91f5;
  color: #fff;
}

.time-slot-label {
  margin:5px 0px 5px 11px;
}

.time-slot-label:nth-child(1) {
  margin-left: 0;
}

.time-slot-label:nth-child(4n + 1) {
  margin-left: 0;
}

.time-slot-label:nth-child(4n) {
  margin-right: 0;
}

#min-time-slot-text {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
}

.delivery-note {
  font-size: 13px;
  font-weight: 300;
  line-height: 1.69;
  text-align: center;
}

.owner-fee-text {
  font-size: 13px;
  font-weight: 300;
}

.total-price {
  padding: 0 15px;
}

.input-with-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px 0;
}

.input-with-label input {
  width: 72px;
  height: 36px;
  margin-bottom: 0;
  border-color: #ebebeb;
}

label {
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

label.switch > input {
  height: auto;
}

.extra-info-container {
  position: relative;
  /* margin: 24px auto 30px;
  padding: 20px 5% 0 5%; */
  border-top: 1px solid #f4f3f3;
  border-bottom: 1px solid #f4f3f3;
  background: #f9f9f9;
  margin-bottom: 30px;
}

/* required for phone number input */
.phone-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #eceef2;
  border-radius: 4px;
}

.phone,
#resend-phone {
  border: none;
  outline: none;
  margin: 0px;
  padding: 0px;
}

.phone[disabled] {
  background-color: #fff;
}

.country-code {
  margin: 0 0 0 16px;
}

.country-code.disabled {
  color: #bfbfbf;
}

.info-text--normal {
  font-size: 16px;
  line-height: 1.25;
  color: #666666;
}

.section-seperator {
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
}

.input-seperator {
  font-size: 28px;
  padding: 0;
  font-weight: 300;
  color: #cecece;
  margin: -5px 8px 0 12px;
}

/* required for on/off switch as checkbox */
label.switch {
  margin-left: 4px;
  min-width: 41px;
  width: 41px;
  height: 24px;
  border-radius: 60px;
  /* background-color:#4ed164; */
  background-color: #2a91f5;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

label.switch > span {
  display: block;
  width: 100%;
  height: 100%;
}

label.switch > input[type='checkbox'] {
  opacity: 0;
  position: absolute;
}

label.switch > span:before,
label.switch > span:after {
  content: '';
  cursor: pointer;
  position: absolute;
}

label.switch > input[type='checkbox']:focus ~ span {
  /* box-shadow: 0 0 0 4px #43b556;  */
  -webkit-box-shadow: 0 0 0 1px #2a91f5;
          box-shadow: 0 0 0 1px #2a91f5;
}

label.switch > input[type='checkbox']:checked:focus ~ span {
  /* box-shadow: 0 0 0 4px #fff; */
  -webkit-box-shadow: 0 0 0 1px #fff;
          box-shadow: 0 0 0 1px #fff;
}

label.switch > span {
  border-radius: 60px;
}

label.switch > span:before {
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #9b9b9b;
  border-radius: 60px;
  -webkit-transition: opacity 0.2s ease-out 0.1s, -webkit-transform 0.2s ease-out 0.1s;
  transition: opacity 0.2s ease-out 0.1s, -webkit-transform 0.2s ease-out 0.1s;
  -o-transition: opacity 0.2s ease-out 0.1s, transform 0.2s ease-out 0.1s;
  transition: opacity 0.2s ease-out 0.1s, transform 0.2s ease-out 0.1s;
  transition: opacity 0.2s ease-out 0.1s, transform 0.2s ease-out 0.1s, -webkit-transform 0.2s ease-out 0.1s;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}

label.switch > span:after {
  top: 50%;
  z-index: 3;
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.44, -0.12, 0.07, 1.15);
  transition: -webkit-transform 0.4s cubic-bezier(0.44, -0.12, 0.07, 1.15);
  -o-transition: transform 0.4s cubic-bezier(0.44, -0.12, 0.07, 1.15);
  transition: transform 0.4s cubic-bezier(0.44, -0.12, 0.07, 1.15);
  transition: transform 0.4s cubic-bezier(0.44, -0.12, 0.07, 1.15), -webkit-transform 0.4s cubic-bezier(0.44, -0.12, 0.07, 1.15);
  /* width: calc(150px / 2);
    height: calc(150px / 2);
    border-radius: 100%; */
  width: 24px;
  height: 24px;
  border-radius: 100%;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
  background-color: #fff;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

label.switch > input[type='checkbox']:checked ~ span:before {
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  opacity: 0.7;
}

label.switch > input[type='checkbox']:checked ~ span:after {
  -webkit-transform: translate3d(100%, -50%, 0);
          transform: translate3d(100%, -50%, 0);
}

.custom-select {
  position: relative;
}

.custom-select select {
  -webkit-appearance: none;
  display: inline-block;
  width: 100%;
  height: 48px;
  padding: 0 8px;
  margin: auto;
  font-size: 1em;
  outline: none;
  background: white;
  color:black;
  border: 1px solid #eceef2;
}
.custom-select select:invalid { color: #757575; }
.custom-select select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.custom-select:after {
  content: "\25be";
  position: absolute;
  top: 0;
  right: .5em;
  bottom: 0;
  font-size: 2em;
  height: 1em;
  margin: auto;
  background: white;
  color: #757575;
  pointer-events: none;
}

@media (min-width: 375px) {
  .custom-select select {
    height: 55px;
  }
}

.terms-links {
  text-align: center;
}

/* required for insurance screen */
#insurance ul {
  display: inline-block;
  margin: 16px auto 8px;
}

#insurance li {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
}

#insurance li:last-child {
  padding: 8px 6px;
}

#insurance li img.check {
  width: 8px;
  height: 8px;
  margin-right: 6px;
  padding: 3px;

}

#insurance li .drop-down-tip {
  left: auto;
  top: auto;
  bottom: -8px;
  z-index: 1;
}

/* @media(min-width: 375px) {
  #insurance li .drop-down-tip.left {
    bottom: -17px;
  }
}

@media(min-width: 425px) {
  #insurance li .drop-down-tip.left {
    bottom: -19px;
  }
} */

#insurance-form {
  position: relative;
}

#insurance-form .form-content {
  padding: 18px 0;
  border-top: 1px solid #f4f3f3;
  border-bottom: 1px solid #f4f3f3;
  background-color: #f9f9f9;
}

#insurance-form .drop-down-tip.left {
  left: 19%;
}

#insurance-premium-container {
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .insurance-premium-chosen, */
#insurance-premium-container input[type=radio]:checked + .insurance-premium {
  background-color: #FFF;
  border:1px solid #161464;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.3);
}

/* .insurance-premium-size-circle-chosen */
#insurance-premium-container input[type=radio]:checked + .insurance-premium > .insurance-premium-size-circle {
  background-color: #161464;
}

/* .insurance-premium-size-text-chosen  */
#insurance-premium-container input[type=radio]:checked + .insurance-premium > .insurance-premium-size-circle > .insurance-premium-size-text {
  color:white;
}

.insurance-premium {
  width: 100%;
  height: 56px;
  margin: 5px;
  padding: 0 20px;
  border-radius: 8px;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: space-between;
  background-color: #FFF;
  border:1px solid #cccccc;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  cursor: pointer;
}

.insurance-premium-image {
  height:80px;
}

.insurance-premium-cost {
  flex-basis: 25%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  font-weight: 900;
  text-align: right;
  white-space: nowrap;
}

.insurance-premium-size-circle {
  min-width: 46px;
  padding: 2px 8px;
  border-radius: 15px;
  background-color: #f4f4f4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

/* .insurance-premium-size-circle-chosen */
#insurance-premium-container input[type=radio]:checked + .insurance-premium .insurance-premium-size-circle {
  background-color: #08042b;
}

.insurance-premium-size-text {
  color: #08042b;
  font-size: 14px;
  font-weight: bold;
}

/* .insurance-premium-size-text-chosen  */
#insurance-premium-container input[type=radio]:checked + .insurance-premium .insurance-premium-size-circle .insurance-premium-size-text {
  color:white;
}

.insurance-premium-name {
  font-size: 18px;
  font-weight: 900;
  text-align: left;
  flex-basis: 25%;
}

.premium-info {
  font-size: 12px;
  text-align: center;
  flex-basis: 50%;
  white-space: nowrap;
}

.weight-800 {
  font-weight: 800;
}

#insurance-form .cta {
  margin-bottom: 22px;
}

#insurance-form .terms-links a {
  font-size: 14px;
}

.size-3 {
  font-size: 16px;
}


/* required for login page */
.form-heading {
  font-size: 24px;
  font-weight: 800;
  text-align: center;
}

#login p,
#resend-code p {
  text-align: center;
}

@media (min-width: 500px) {
  #login-form,
  #resend-form {
    padding: 42px 0 12px;
  }
}

/* required for login success page */
.login-success,
.login-success .inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.login-success p {
  text-align: center;
}

.heading-top {
  width: 100%;
  padding: 12px;
  margin-bottom: 36px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #000;
  text-align: center;
}

@media (min-width: 500px) {
  .heading-top {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}

.heading-top p {
  color: #fff;
}

.helpers {
  margin-bottom: 12px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.vehicle-logo-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 95px;
  height: 95px;
  margin-bottom: 16px;
  border-radius: 100%;
  background-color: #ffd13f;
}

.login-iphone-awards-image {
  display: block;
  margin: 10px auto 5px;
  width: 300px;
  max-width: 100%;
}

.vehicle-icon {
  width: 54px;
  height: 36px;
}

.headline {
  width: 88%;
  margin-top: 24px;
  margin-bottom: 32px;
}

@media(min-width: 500px){
  .headline {
    margin-top: 48px;
  }
}

.spinner-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  top: 0px;
  left: 66px;
  width: 29px;
  height: 29px;
  border-radius: 100%;
  background-color: #000;
}

.helper-count {
  color: #fff;
  text-align: center;
}

.request-container {
  position: relative;
  padding: 6px 12px;
  background-color: #08d88c;
  margin-left: 12px;
  border-radius: 4px;
}

.request-container p {
  color: #fff;
  font-size: 16px;
}

.pointer-left {
  position: absolute;
  top: -6px;
  left: 69px;
  width: 0;
  height: 0;
  /* border-top: 4px solid transparent;
  border-right: 8px solid #08d88c;
  border-bottom: 4px solid transparent; */

  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 8px solid #08d88c;
}

.size-text-divider {
  margin-top: 20px;
  margin-left: 30px;
}

#size-text-container {
  margin-bottom: 40px;
}

div.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 40px;
}

#medium-car {
  text-align: center;
}

#large-car {
}

#address-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient:horizontal;
  -webkit-box-direction:normal;
      -ms-flex-direction:row;
          flex-direction:row;
}

#dim-screen {
    position:fixed;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background:rgba(0,0,0,0.8);
    display: none;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-animation: fade 0.5s ease-in;
    -webkit-animation-fill-mode: forwards;
    z-index: 2000;
}

@-webkit-keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

#error-modal {
  max-width:200px;
  background-color: white;
  border-radius:10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding:50px;
}

#error-modal-image {
  width:140px;
  height:140px;
}

#server-error-message {
  text-align: center;
}

#price-boxes-container {
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 7px -5px;
}

.price-box {
  width: 20%;
  width: calc(25% - 10px);
  height:100px;
  margin:5px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: flex-start;
  border:1px solid #ebebeb;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.price-box-tapaway-1 { background-image:url(/img/tapaway1.png); }
.price-box-h2t-1 { background-image:url(/img/heretothere1.png); }
.price-box-tapaway-2 { background-image:url(/img/tapaway2.png); }
.price-box-h2t-2 { background-image:url(/img/heretothere2.png); }
.price-box-tapaway-3 { background-image:url(/img/tapaway3.png); }
.price-box-h2t-3 { background-image:url(/img/heretothere3.png); }
.price-box-tapaway-4 { background-image:url(/img/tapaway4.png); }
.price-box-h2t-4 { background-image:url(/img/heretothere4.png); }

/* .price-box-chosen, */
#price-boxes-container input[type=radio]:checked + .price-box {
  border:1px solid #08042b;
}

.error-text {
  font-size: 16px;
  color:#ee0c28
}

.warning-text {
  font-size: 14px;
  color: #ffc107;
}

.info-text {
  font-size: 14px;
  color: #8a8989;
}

.price-box-image {
  height:80px;
}

.price-box-size-circle {
  height: 19px;
  margin-top: 10px;
  margin-right: calc(100% - 29px);
  width: 30px;
  border-radius: 0px 10px 10px 0px;
  background-color: #00000061;
  display: flex;
  color: white;
  align-items: flex-start;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

/* .price-box-size-circle-chosen */
#price-boxes-container input[type=radio]:checked + .price-box > .price-box-size-circle {
  background-color: #08042b;
}

.price-box-size-text {
  color: #f4f3f3;
  font-size: 11px;
  font-weight: bold;
}

/* .price-box-size-text-chosen  */
#price-boxes-container input[type=radio]:checked + .price-box > .price-box-size-circle > .price-box-size-text {
  color:white;
}

#uglipop_content_fixed {
  top: 15px;
  left: 15px;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  z-index: 200;
}

#uglipop_content_fixed {
  top: 32px;
  left: 32px;
  z-index: 3000;
  max-width: 900px;
}

.dialog {
  border-radius: 10px;
  background-color:white;
  /* height: 400px; */
  height: calc(100% - 30px);
  padding:10px;
  -webkit-box-shadow: 0px 0px 39px 12px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 39px 12px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 39px 12px rgba(0,0,0,0.75);
}

.dialogInner {
  height: 100%;
  overflow: scroll;
  font-size: 14px;
  -webkit-overflow-scrolling: touch;
}

.dialogInner h1 {
  font-size: 20px;
  line-height: unset;
}

.dialogInner h2 {
  font-size: 16px;
  line-height: unset;
}

.dialogInner h3 {
  font-size: 14px;
  margin: 20px 0 10px 0;
  line-height: unset;
}

.dialogInner p {
  margin-bottom: 10px;
  font-size: 14px;
}

.dialog .dialogInner,
.dialog .dialogInner h1,
.dialog .dialogInner h2,
.dialog .dialogInner h3,
.dialog .dialogInner p  {
  text-align: left;
}

.dialogCloseButton {
  position: fixed;
  top: 0;
  right: 0;
  padding: 5px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 48px;
  line-height: 24px;
  font-weight: 300;
  cursor: pointer;
  text-align: right;
}

.dialogCloseButton:hover {
  opacity: 0.5;
}

@media (min-width: 500px) {
	.dialogCloseButton {
		top: 16px;
		right: 16px;
		font-size: 64px;
    line-height: 32px;
    background-color: transparent;
	}
}

.wasteCategory {
  display: none;
}
.wasteCategory + label {
  display: block;
  cursor: pointer;
  display: flex;
  user-select: none;
  font-size: 15px;
  font-weight: normal;
}
.wasteCategory[disabled] + label {
  cursor: default;
}
.wasteCategory + label img.icon {
  width: 45px;
  height: 45px;
  margin-right: 10px;
  border-radius: 6px;
}
.wasteCategory + label img.check {
  width: 20px;
  margin-left: auto;
}
.wasteCategory + label img.icon-info {
  margin-left: 10px;
  cursor: pointer;
}
.wasteCategory + label img.notSelected { display: block; }
.wasteCategory + label img.selected { display: none; }
.wasteCategory + label img.check { display: none; }
.wasteCategory:checked + label img.notSelected { display: none; }
.wasteCategory:checked + label img.selected { display: block; }
.wasteCategory:checked + label img.check { display: block; }

#waste-category-rule-template {
  display: none;
}

#waste-category-rules-submit-btn {
  display: block;
}

.waste-categories-back-btn {
  display: block;
  text-transform: uppercase;
}

.waste-category-rule-header {
  display: flex;
  align-items: center;
}

.waste-category-rule-icons img {
  width: 45px;
  height: 45px;
  margin-right: 10px;
}

.waste-category-rule-description {
  margin-top: 20px;
  font-size: 16px;
}

.waste-category-rule-options-header {
  margin-top: 16px;
  text-align: center;
  font-size: 16px;
}

.waste-category-rule-options {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}

.waste-category-option input {
  display: none;
}

.waste-category-option input + span {
  display: inline-block;
  width: 100px;
  height: 40px;
  border-radius: 4px;
  background-color: #e5e5e5;
  line-height: 40px;
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 900;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.waste-category-option input:checked + span {
  color: #fff;
  background-color: #2a91f5;
}

.waste-category-rule-body {
  margin-top: 20px;
  font-size: 16px;
}

.waste-category-rule-terms {
  display: flex;
  justify-content: center;
}

.styled-checkbox {
  display: none;
}
.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.styled-checkbox + label:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  border: solid 1px rgba(155, 155, 155, 0.44);
  border-radius: 4px;
  width: 23px;
  height: 23px;
  background: white;
}
.styled-checkbox:checked + label:after {
  content: '';
  position: absolute;
  height: 8px;
  width: 14px;
  border-left: 3px solid #2a91f5;
  border-bottom: 3px solid #2a91f5;

  transform: rotate(-45deg);

  left: 4px;
  top: 5px;
}

.termsCheckBox + label {
  margin-left: 14px;
  font-size: 14px;
  color: #666666;
}

ul.termsList {
  padding: 5px 20px 0px 65px;
  list-style: disc outside none;
  font-size: 14px;
  color: #666666;
  line-height: 16px;
}

ul.termsList > li {
  margin-bottom: 10px;
}

.termsListContainer {

}


/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
 .la-line-spin-fade,
 .la-line-spin-fade > div {
     position: relative;
     -webkit-box-sizing: border-box;
             box-sizing: border-box;
 }
 .la-line-spin-fade {
     display: block;
     font-size: 0;
     color: #fff;
 }
 .la-line-spin-fade.la-dark {
     color: #333;
 }
 .la-line-spin-fade > div {
     display: inline-block;
     float: none;
     background-color: currentColor;
     border: 0 solid currentColor;
 }
 .la-line-spin-fade {
     width: 32px;
     height: 32px;
 }
 .la-line-spin-fade > div {
     position: absolute;
     width: 2px;
     height: 10px;
     margin: 2px;
     margin-top: -5px;
     margin-left: -1px;
     border-radius: 0;
     -webkit-animation: line-spin-fade 1s infinite ease-in-out;
             animation: line-spin-fade 1s infinite ease-in-out;
 }
 .la-line-spin-fade > div:nth-child(1) {
     top: 15%;
     left: 50%;
     -webkit-transform: rotate(0deg);
         -ms-transform: rotate(0deg);
             transform: rotate(0deg);
     -webkit-animation-delay: -1.125s;
             animation-delay: -1.125s;
 }
 .la-line-spin-fade > div:nth-child(2) {
     top: 25.2512626585%;
     left: 74.7487373415%;
     -webkit-transform: rotate(45deg);
         -ms-transform: rotate(45deg);
             transform: rotate(45deg);
     -webkit-animation-delay: -1.25s;
             animation-delay: -1.25s;
 }
 .la-line-spin-fade > div:nth-child(3) {
     top: 50%;
     left: 85%;
     -webkit-transform: rotate(90deg);
         -ms-transform: rotate(90deg);
             transform: rotate(90deg);
     -webkit-animation-delay: -1.375s;
             animation-delay: -1.375s;
 }
 .la-line-spin-fade > div:nth-child(4) {
     top: 74.7487373415%;
     left: 74.7487373415%;
     -webkit-transform: rotate(135deg);
         -ms-transform: rotate(135deg);
             transform: rotate(135deg);
     -webkit-animation-delay: -1.5s;
             animation-delay: -1.5s;
 }
 .la-line-spin-fade > div:nth-child(5) {
     top: 84.9999999974%;
     left: 50.0000000004%;
     -webkit-transform: rotate(180deg);
         -ms-transform: rotate(180deg);
             transform: rotate(180deg);
     -webkit-animation-delay: -1.625s;
             animation-delay: -1.625s;
 }
 .la-line-spin-fade > div:nth-child(6) {
     top: 74.7487369862%;
     left: 25.2512627193%;
     -webkit-transform: rotate(225deg);
         -ms-transform: rotate(225deg);
             transform: rotate(225deg);
     -webkit-animation-delay: -1.75s;
             animation-delay: -1.75s;
 }
 .la-line-spin-fade > div:nth-child(7) {
     top: 49.9999806189%;
     left: 15.0000039834%;
     -webkit-transform: rotate(270deg);
         -ms-transform: rotate(270deg);
             transform: rotate(270deg);
     -webkit-animation-delay: -1.875s;
             animation-delay: -1.875s;
 }
 .la-line-spin-fade > div:nth-child(8) {
     top: 25.2506949798%;
     left: 25.2513989292%;
     -webkit-transform: rotate(315deg);
         -ms-transform: rotate(315deg);
             transform: rotate(315deg);
     -webkit-animation-delay: -2s;
             animation-delay: -2s;
 }
 .la-line-spin-fade.la-sm {
     width: 16px;
     height: 16px;
 }
 .la-line-spin-fade.la-sm > div {
     width: 1px;
     height: 4px;
     margin-top: -2px;
     margin-left: 0;
 }
 .la-line-spin-fade.la-2x {
     width: 64px;
     height: 64px;
 }
 .la-line-spin-fade.la-2x > div {
     width: 4px;
     height: 20px;
     margin-top: -10px;
     margin-left: -2px;
 }
 .la-line-spin-fade.la-3x {
     width: 96px;
     height: 96px;
 }
 .la-line-spin-fade.la-3x > div {
     width: 6px;
     height: 30px;
     margin-top: -15px;
     margin-left: -3px;
 }
 /*
  * Animation
  */
 @-webkit-keyframes line-spin-fade {
     50% {
         opacity: .2;
     }
     100% {
         opacity: 1;
     }
 }
 @keyframes line-spin-fade {
     50% {
         opacity: .2;
     }
     100% {
         opacity: 1;
     }
 }
